import axios from "axios";
import Cookies from "js-cookie";

const API_URL = `${process.env.REACT_APP_BASE_URL_BACK}/api/cart`;

const getAuthHeaders = () => {
  const token = localStorage.getItem("token");
  return token ? { "x-auth-token": token } : {};
};

export const addToCart = async (userId, productId, quantity) => {
  try {
    await axios.post(
      `${API_URL}/add`,
      { productId, quantity },
      {
        headers: getAuthHeaders(),
        withCredentials: true,
      }
    );
  } catch (error) {
    console.error("Error adding to cart:", error);
    throw error;
  }
};

export const mergeCarts = async (sessionCart, userId) => {
  try {
    const { data: userCart } = await axios.get(`${API_URL}`, {
      headers: getAuthHeaders(),
      withCredentials: true,
    });

    for (const sessionItem of sessionCart) {
      const existingItem = userCart.find(
        (item) => item.productId === sessionItem.productId
      );

      const quantity = existingItem
        ? sessionItem.quantity + existingItem.quantity
        : sessionItem.quantity;

      await addToCart(userId, sessionItem.productId, quantity);
    }
  } catch (error) {
    console.error("Error merging carts:", error);
  }
};

export const removeFromCart = async (productId) => {
  try {
    const sessionId = Cookies.get("sessionId");
    await axios.post(
      `${API_URL}/remove`,
      { productId },
      {
        headers: getAuthHeaders(),
        withCredentials: true,
        params: sessionId ? { sessionId } : {},
      }
    );
  } catch (error) {
    console.error("Error removing from cart:", error);
    throw error;
  }
};

export const getCart = async () => {
  try {
    const response = await axios.get(API_URL, {
      headers: getAuthHeaders(),
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching cart:", error);
    throw error;
  }
};

export const updateCartQuantity = async (productId, quantity) => {
  try {
    const response = await axios.post(
      `${API_URL}/update-quantity`,
      { productId, quantity },
      {
        headers: getAuthHeaders(),
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating cart quantity:", error);
    throw error;
  }
};
