import React, { useEffect, useCallback, useState } from "react";
import { useCart } from "../Context/CartContext";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import { removeFromCart } from "../service/cartService";
import PaypalPayment from "./PayPalPayment";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import paniervide from "../assets/videpanier.jpg";
import Footer from "./Footer";

const CartPage = () => {
  const { state, dispatch, loadCart } = useCart();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const handleRemoveFromCart = useCallback(
    async (productId) => {
      try {
        dispatch({
          type: "REMOVE_FROM_CART_START",
          payload: { _id: productId },
        });
        await removeFromCart(productId);
        dispatch({
          type: "REMOVE_FROM_CART_SUCCESS",
          payload: { _id: productId },
        });

        await loadCart(); // Recharger le panier depuis le serveur
      } catch (error) {
        console.error("Error removing from cart:", error);
      }
    },
    [dispatch, loadCart]
  );

  const handleLogin = () => navigate("/login");

  useEffect(() => {
    const loadData = async () => {
      await loadCart();
      setLoading(false);
    };
    loadData();
  }, [loadCart]);

  const totalCartPrice = state.products
    .reduce((total, item) => {
      if (!item.productId) return total;
      return total + item.productId.price * item.quantity;
    }, 0)
    .toFixed(2);

  if (loading) {
    return <p>Chargement...</p>;
  }

  return (
    <>
      <div className="container mx-auto p-6 min-h-screen">
        <h1 className="text-4xl font-bold mb-6 text-center">Votre Panier</h1>
        {state.products.length === 0 ? (
          <div className="flex flex-col items-center">
            <img
              src={paniervide}
              alt="Panier vide"
              className="w-64 h-64 mb-4"
            />
            <p className="text-gray-700">Votre panier est vide.</p>
          </div>
        ) : (
          <div className="flex flex-col lg:flex-row gap-6">
            <div className="flex-1">
              {state.products.map((item, index) => {
                const { productId, quantity } = item;
                if (!productId) return null; // Skip if no productId
                return (
                  <div
                    key={productId._id}
                    className="flex items-center bg-white p-4 mb-4 border border-gray-200 rounded-lg"
                  >
                    <div className="w-24 h-24">
                      <img
                        src={
                          productId.images?.length
                            ? `${process.env.REACT_APP_BASE_URL_BACK}/${productId.images[0]}`
                            : "https://via.placeholder.com/150"
                        }
                        alt={productId.name || "Product Image"}
                        className="w-full h-full object-cover rounded-lg"
                      />
                    </div>
                    <div className="flex-grow ml-4 flex justify-between items-center">
                      <div>
                        <h2 className="text-lg font-semibold mb-2">
                          {productId.name}
                        </h2>
                        <p className="text-gray-700 mb-2">
                          Quantité: {quantity}
                        </p>
                      </div>
                      <div className="text-right">
                        <p className="text-gray-700 text-lg font-semibold">
                          {productId.price}€
                        </p>
                        <button
                          className="text-red-500 hover:text-red-700 mt-2"
                          onClick={() => handleRemoveFromCart(productId._id)}
                        >
                          Supprimer
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="lg:w-1/3 bg-white p-6 rounded-lg border border-gray-200">
              <h2 className="text-2xl font-bold text-gray-800 mb-4">
                Récapitulatif de la commande
              </h2>
              <div className="mb-4">
                <h3 className="text-lg font-medium text-gray-700">
                  Total : <span>{totalCartPrice}€</span>
                </h3>
              </div>
              {user ? (
                <PayPalScriptProvider
                  options={{
                    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                    currency: "EUR",
                  }}
                >
                  <PaypalPayment />
                </PayPalScriptProvider>
              ) : (
                <button
                  onClick={handleLogin}
                  className="w-full bg-green-600 text-white px-6 py-3 rounded-lg"
                >
                  Se connecter pour finaliser votre commande
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default CartPage;
