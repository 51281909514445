import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Home from "./Components/Home";
import Epices from "./Components/Epices";
import EpicesDetails from "./Components/EpicesDetails";
import AmuseBouche from "./Components/AmuseBouche";
import Contact from "./Components/Contact";
import AdminLayout from "./Admin/AdminLayout";
import AdminUsers from "./Admin/AdminUsers";
import AdminOrders from "./Admin/AdminOrders";
import AdminPage from "./Admin/AdminPage";
import AdminProducts from "./Admin/AdminProducts";
import Auth from "./Components/Auth";
import CartPage from "./Components/CartPage";
import { AuthProvider } from "./Context/AuthContext";
import { CartProvider } from "./Context/CartContext";
import CartDrawer from "./Components/CartDrawer";
import Checkout from "./Components/Checkout";
import OrderSuccess from "./Components/OrderSuccess";
import AmuseBoucheDetails from "./Components/AmuseBoucheDetails";
import Mugs from "./Components/Mugs";
import MugsDetails from "./Components/MugsDetails";

function App() {
  const [isCartOpen, setCartOpen] = useState(false);

  const toggleCart = () => {
    setCartOpen(!isCartOpen);
  };

  return (
    <CartProvider>
      <AuthProvider>
        <Router>
          <div>
            <Navbar toggleCart={toggleCart} />
            <CartDrawer isOpen={isCartOpen} onClose={toggleCart} />
            <Routes>
              <Route path="/" exact element={<Home />} />
              <Route path="/epices" element={<Epices />} />
              <Route path="/epices/:id" element={<EpicesDetails />} />
              <Route path="/amuse-bouche" element={<AmuseBouche />} />
              <Route path="/mugs" element={<Mugs />} />
              <Route path="/mugs/:id" element={<MugsDetails />} />
              <Route
                path="/amuse-bouche/:id"
                element={<AmuseBoucheDetails />}
              />
              <Route path="/contact" element={<Contact />} />
              <Route path="/cart" element={<CartPage />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/order-success" element={<OrderSuccess />} />
              <Route path="/admin" element={<AdminLayout />}>
                <Route index element={<AdminPage />} />
                <Route path="users" element={<AdminUsers />} />
                <Route path="orders" element={<AdminOrders />} />
                <Route path="products" element={<AdminProducts />} />
              </Route>
              <Route path="/login" element={<Auth />} />
            </Routes>
          </div>
        </Router>
      </AuthProvider>
    </CartProvider>
  );
}

export default App;
