import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { FaUser, FaShoppingCart, FaBars, FaTimes } from "react-icons/fa";
import logo from "../assets/logo.png";
import { AuthContext } from "../Context/AuthContext"; // Importer AuthContext
import { useCart } from "../Context/CartContext"; // Importer CartContext

const Navbar = ({ toggleCart }) => {
  const { user, logout } = useContext(AuthContext);
  const { state } = useCart();
  const [isOpen, setIsOpen] = useState(false);

  // Calculer la quantité totale de produits dans le panier
  const totalQuantity = state.products.reduce(
    (acc, product) => acc + product.quantity,
    0
  );

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-gray-800 p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center">
          <img src={logo} className="h-16 w-auto mr-4" alt="Logo" />
          <span className="text-white text-2xl font-bold">Epices Royales</span>
        </div>

        {/* Hamburger menu icon for small screens */}
        <div className="lg:hidden">
          <button onClick={toggleMenu} className="text-white">
            {isOpen ? <FaTimes size="1.5em" /> : <FaBars size="1.5em" />}
          </button>
        </div>

        {/* Links section centered for large screens */}
        <div className="hidden lg:flex lg:justify-center lg:space-x-8 lg:flex-grow">
          <Link to="/" className="text-white">
            Home
          </Link>
          <Link to="/epices" className="text-white">
            Epices
          </Link>
          <Link to="/amuse-bouche" className="text-white">
            Amuse Bouche
          </Link>
          <Link to="/mugs" className="text-white">
            Mugs
          </Link>
          {user && user.role === "admin" && (
            <Link to="/admin" className="text-white">
              Admin
            </Link>
          )}
        </div>

        {/* Cart and user icons for large screens */}
        <div className="hidden lg:flex items-center space-x-4">
          <div className="relative">
            <FaShoppingCart
              className="text-white cursor-pointer"
              size="1.5em"
              onClick={toggleCart}
            />
            {totalQuantity > 0 && (
              <div className="absolute -top-2 -right-2 bg-red-600 text-white rounded-full h-5 w-5 flex items-center justify-center text-xs font-bold">
                {totalQuantity}
              </div>
            )}
          </div>
          {user ? (
            <div className="flex items-center space-x-4">
              <span className="text-white">Welcome, {user.username}</span>
              <button onClick={logout} className="text-white">
                Logout
              </button>
            </div>
          ) : (
            <Link to="/login" className="text-white flex items-center">
              <FaUser className="mr-2" /> Login
            </Link>
          )}
        </div>
      </div>

      {/* Dropdown menu for small screens */}
      {isOpen && (
        <div className="lg:hidden bg-gray-800">
          <Link
            to="/"
            className="block text-white py-4 text-center border-b border-gray-700"
            onClick={toggleMenu}
          >
            Home
          </Link>
          <Link
            to="/epices"
            className="block text-white py-4 text-center border-b border-gray-700"
            onClick={toggleMenu}
          >
            Epices
          </Link>
          <Link
            to="/amuse-bouche"
            className="block text-white py-4 text-center border-b border-gray-700"
            onClick={toggleMenu}
          >
            Amuse Bouche
          </Link>
          <Link
            to="/mugs"
            className="block text-white py-4 text-center border-b border-gray-700"
            onClick={toggleMenu}
          >
            Mugs
          </Link>
          {user && user.role === "admin" && (
            <Link
              to="/admin"
              className="block text-white py-4 text-center border-b border-gray-700"
              onClick={toggleMenu}
            >
              Admin
            </Link>
          )}

          {/* Cart and user icons inside the dropdown for small screens */}
          <div className="flex flex-col items-center space-y-4 py-4">
            <div className="flex items-center space-x-2">
              <FaShoppingCart
                className="text-white cursor-pointer"
                size="1.5em"
                onClick={toggleCart}
              />
              <button className="text-white" onClick={toggleCart}>
                Panier
              </button>
              {totalQuantity > 0 && (
                <div className="relative">
                  <div className="absolute -top-2 -right-2 bg-red-600 text-white rounded-full h-5 w-5 flex items-center justify-center text-xs font-bold">
                    {totalQuantity}
                  </div>
                </div>
              )}
            </div>

            {/* Separation between the Cart and Login */}
            <div className="w-full border-b border-gray-700"></div>

            <div className="flex items-center space-x-2">
              {user ? (
                <>
                  <FaUser className="text-white" />
                  <button onClick={logout} className="text-white">
                    Logout
                  </button>
                </>
              ) : (
                <Link to="/login" className="text-white flex items-center">
                  <FaUser className="mr-2" /> Login
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
