import React from "react";
import { Link } from "react-router-dom";

const AdminMenu = () => {
  return (
    <div className="w-64 h-screen bg-gray-800 text-white">
      <div className="p-4">
        <h2 className="text-2xl font-bold mb-4">Admin Menu</h2>
        <ul>
          <li className="mb-2">
            <Link to="/admin/users" className="text-white hover:underline">
              Manage Users
            </Link>
          </li>
          <li className="mb-2">
            <Link to="/admin/orders" className="text-white hover:underline">
              Manage Orders
            </Link>
          </li>
          <li className="mb-2">
            <Link to="/admin/products" className="text-white hover:underline">
              Manage Products
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AdminMenu;
