import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getProducts } from "../service/productsService"; // Ensure this path is correct
import { capitalizeFirstLetter } from "./utils/capitalizeFirstLetter";
import Footer from "./Footer";

const Mugs = () => {
  const [mugs, setMugs] = useState([]);

  useEffect(() => {
    const fetchMugs = async () => {
      try {
        const products = await getProducts();
        const mugsProducts = products.filter(
          (product) => product.category === "mugs"
        );
        setMugs(mugsProducts);
      } catch (error) {
        console.error("Error fetching the mugs:", error);
      }
    };

    fetchMugs();
  }, []);

  return (
    <>
      <div className="bg-gray-200 min-h-screen flex flex-col">
        <div className="container mx-auto p-4 flex-grow">
          <h1 className="text-4xl font-bold my-8 text-center">Nos Mugs</h1>
          {mugs.length === 0 ? (
            <p className="text-gray-700 text-center">
              Aucun produit trouvé dans la catégorie mugs.
            </p>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 my-20">
              {mugs.map((mug) => (
                <div
                  key={mug._id}
                  className="bg-white p-4 shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300"
                >
                  <img
                    src={
                      mug.images.length > 0
                        ? `${process.env.REACT_APP_BASE_URL_BACK}/${mug.images[0]}`
                        : "https://via.placeholder.com/150"
                    }
                    alt={mug.name}
                    className="w-full h-48 object-cover mb-4 rounded-lg"
                  />
                  <h2 className="text-xl font-semibold mb-2 text-center">
                    {capitalizeFirstLetter(mug.name)}
                  </h2>
                  <p className="text-gray-700 text-center mb-4">{mug.price}€</p>
                  <div className="text-center">
                    <Link to={`/mugs/${mug._id}`}>
                      <button className="bg-yellow-500 hover:bg-yellow-600 text-white px-6 py-2 rounded-md transition-colors duration-300">
                        Voir les détails
                      </button>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Mugs;
