import axios from "axios";

const API_URL = `${process.env.REACT_APP_BASE_URL_BACK}/api/products`;
console.log("Backend API URL:", process.env.REACT_APP_BASE_URL_BACK);

export const getProducts = async () => {
  try {
    const response = await axios.get(API_URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createProduct = async (product) => {
  try {
    const response = await axios.post(`${API_URL}/add`, product, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteImageProduct = async (productId, imagePath) => {
  try {
    const response = await axios.delete(`${API_URL}/delete-image`, {
      data: { productId, imagePath },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
