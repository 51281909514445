import React from "react";
import { Link as ScrollLink, Element } from "react-scroll";
import { Link } from "react-router-dom";
import bgImage from "../assets/another_bg_epices_royales.jpg"; // Importer l'image de fond
import ownerImage from "../assets/p1.png"; // Assurez-vous d'importer l'image du propriétaire
import companyImage from "../assets/p2.png"; // Image de l'entreprise (vous pouvez changer le nom de fichier)
import Footer from "./Footer";

const Home = () => {
  return (
    <>
      <div className="font-sans">
        {/* Section principale avec l'image de fond */}
        <div
          className="relative bg-cover bg-center min-h-screen flex items-center justify-center"
          style={{ backgroundImage: `url(${bgImage})` }} // Utiliser l'image importée
        >
          {/* Superposition pour améliorer la lisibilité du texte */}
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
          <div className="relative z-10 container mx-auto p-4 text-center text-white">
            <h1 className="font-montserrat text-4xl md:text-5xl lg:text-6xl font-bold mb-6">
              Bienvenue chez Epices Royales!
            </h1>
            <p className="mb-8 text-base md:text-lg lg:text-xl">
              Nous offrons une large sélection d'épices exotiques et de haute
              qualité pour satisfaire toutes vos envies culinaires.
            </p>
            <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
              <ScrollLink
                to="companySection"
                smooth={true}
                duration={500}
                className="cursor-pointer bg-white hover:bg-black text-black hover:text-white font-semibold py-3 px-6 sm:px-8 rounded-full shadow-lg transition duration-300"
              >
                En savoir plus
              </ScrollLink>
              <Link
                className="bg-yellow-500 hover:bg-yellow-600 text-black font-semibold py-3 px-6 sm:px-8 rounded-full shadow-lg transition duration-300"
                to="/epices"
              >
                Découvrir nos produits
              </Link>
            </div>
          </div>
        </div>

        {/* Section de présentation de l'entreprise */}
        <Element name="companySection" className="bg-white py-16 px-4">
          <div className="container mx-auto flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 p-4">
              <img
                src={companyImage}
                alt="Épices de qualité"
                className="rounded-lg shadow-lg w-full"
              />
            </div>
            <div className="md:w-1/2 p-4">
              <h2 className="font-montserrat text-3xl md:text-4xl font-bold mb-4 text-gray-800">
                Présentation de l'entreprise
              </h2>
              <p className="text-lg md:text-xl text-gray-700">
                Notre entreprise, Épices Royale, est dédiée à apporter les
                épices les plus fines et les plus exotiques de partout dans le
                monde. Chaque épice est soigneusement sélectionnée pour sa
                qualité, sa saveur et son arôme. Nous croyons que les épices
                sont l'âme de la cuisine, et nous nous efforçons de fournir les
                meilleurs produits pour enrichir vos plats et éveiller vos sens.
              </p>
            </div>
          </div>
        </Element>

        {/* Section de présentation du propriétaire */}
        <div className="bg-gray-100 py-16 px-4">
          <div className="container mx-auto flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 p-4 order-2 md:order-1">
              <h2 className="font-montserrat text-3xl md:text-4xl font-bold mb-4 text-gray-800">
                Présentation du propriétaire
              </h2>
              <p className="text-lg md:text-xl text-gray-700">
                Notre propriétaire, de-SOUZA Edith, est un passionné de cuisine
                et un expert en épices avec plus de 20 ans d'expérience dans le
                domaine. Il sélectionne personnellement chaque épice pour
                garantir la meilleure qualité à nos clients. Sa passion et son
                expertise se reflètent dans chaque produit que nous offrons.
              </p>
            </div>
            <div className="md:w-1/2 p-4 order-1 md:order-2">
              <img
                src={ownerImage}
                alt="Propriétaire"
                className="rounded-lg shadow-lg w-full"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
