import React from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { useCart } from "../Context/CartContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const PaypalPayment = () => {
  const { state, dispatch } = useCart(); // Get dispatch from CartContext
  const navigate = useNavigate();

  const createOrder = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_BACK}/api/paypal/create-order`,
        {
          items: state.products.map((product) => ({
            name: product.productId.name,
            quantity: product.quantity,
            price: product.productId.price,
          })),
          totalAmount: state.products.reduce(
            (total, product) =>
              total + product.productId.price * product.quantity,
            0
          ),
        }
      );
      return response.data.orderID; // Ensure that this returns the order ID
    } catch (error) {
      console.error("Failed to create PayPal order:", error);
      throw error; // Throw error to handle it in the calling function
    }
  };

  const onApprove = async (data) => {
    try {
      console.log("Order ID:", data.orderID); // Debug log to verify order ID

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_BACK}/api/paypal/capture-payment?orderId=${data.orderID}`
      );
      console.log("Payment successful:", response.data);

      // Clear the cart after successful payment
      dispatch({ type: "CLEAR_CART" });
      console.log("Dispatched CLEAR_CART"); // Debug log

      localStorage.removeItem("cart");
      console.log("localStorage cart cleared");

      navigate("/order-success");
    } catch (error) {
      console.error("Payment capture failed:", error);
      navigate("/order-fail");
    }
  };

  const onError = (err) => {
    console.error("PayPal payment error:", err);
    navigate("/order-fail"); // Redirect to a fail page on error
  };

  return (
    <div>
      <PayPalButtons
        createOrder={async (data, actions) => {
          try {
            const orderId = await createOrder();
            return orderId; // Return the valid order ID
          } catch (error) {
            return actions.reject(); // Reject the PayPal order creation if an error occurs
          }
        }}
        onApprove={onApprove}
        onError={onError}
      />
    </div>
  );
};

export default PaypalPayment;
